exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-attorneys-js": () => import("./../../../src/pages/attorneys.js" /* webpackChunkName: "component---src-pages-attorneys-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policies-js": () => import("./../../../src/pages/privacy-policies.js" /* webpackChunkName: "component---src-pages-privacy-policies-js" */),
  "component---src-pages-transactions-js": () => import("./../../../src/pages/transactions.js" /* webpackChunkName: "component---src-pages-transactions-js" */)
}

